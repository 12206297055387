/* Slideshow for mobile devices */


/* Slideshow for laptop devices */
.slide-laptop {
    position: relative;
    height: 560px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

.p-laptop {
    font-size: 2rem;
    font-weight: bold;
}

.innerp {
    font-size: 1.2rem;
    color: #fb923c;
}


.main-menu {
    width: 100%;
    height: auto;
}


.width-full {
    width: 100%;
}

.sub-main {
    display: grid;
    width: 100%;
    grid-template-columns: 120px 1fr 2fr 60px;
    grid-template-rows: 1fr;
}

.parent-component {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    width: 100%;
    height: 60px;
    align-items: center;
    position: relative;
    padding: 2px;
    justify-items: center;
}

.details {
    display: grid;
    position: absolute;
    grid-column-start: 1;
    grid-column-end: auto;
    left: 0;
    top: 60px;
    height: 100px;
    width: 100%;
}

.itms {
    color: #555;
    font-size: 16px;
    text-transform: none;
    text-decoration: none;
    font-weight: normal;
    text-align: left;
    margin: 0px 10px 0px 10px;
    padding: 2px;
    display: inline;
    border-bottom: 1px solid #009ddc;
}

.submenu {
    position: absolute;
    left: 0;
    top: 10;
    z-index: 40;
    background-color: #e3c116;
    margin-left: 20px;
    cursor: pointer;
    width: 100%;
    padding: 10px;
    list-style: none;
    margin: 0;
}

.custom-font {
    font-size: 14px;
}

.icon {
    position: absolute;
    left: 10px;
    top: 30%;
    cursor: pointer;
    z-index: 2;
    opacity: 0.5;
    height: 400px;
    width: 100px;
}

.icon2 {
    position: absolute;
    right: 10px;
    top: 30%;
    cursor: pointer;
    z-index: 2;
    opacity: 0.5;
    height: 400px;
    width: 100px;
}

/* sight seen  */
.one-way {
    position: absolute;
    top: 100%;
    /* Position submenu below the parent */
    left: 0;
    width: 100%;
    background-color: #ffe066;
    /* Adjust background color as needed */
    border: 1px solid #ccc;
    /* Example border styling */
    padding: 5px;
}

.open-oneway:hover .one-way {
    display: block;
    /* Show submenu when hovering over .open-oneway */
}

/* sight seen end */

.mobile-submenu-maincontainer {
    display: none;
}

@media only screen and (max-width: 749px) {

    .slide-mobile {
        height: 350px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

    }

    .p-mobile {
        font-weight: bold;

        font-size: 25px;
    }

    .innerp {
        font-size: 0.8rem;
        color: #fb923c;
    }

    .sub-main {
        display: none;
    }

    .mobile-submenu-maincontainer {
        display: block;
        width: 100%;
        position: relative;
    }

    .menu-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-right: 30px;
        height: 50px;
        padding: 10px;
        align-items: left;
    }

    .menu-box {
        display: flex;
        width: 100%;
        padding: 10px;
        background-color: rgb(237, 208, 60);
        gap: 20px;
        font-size: large;
    }

    .icon {
        position: absolute;
        left: 10px;
        top: 50%;
        cursor: pointer;
        z-index: 2;
        opacity: 0.6;
        height: 130px;
        width: 100px;
    }

    .icon2 {
        position: absolute;
        right: 10px;
        top: 50%;
        cursor: pointer;
        z-index: 2;
        opacity: 0.6;
        height: 130px;
        width: 100px;
    }
}

@media only screen and (min-width: 749px) and (max-width: 1057px) {
    .main-menu {
        width: 100%;
        height: auto;
    }

    .custom-font {
        font-size: 12px;
    }

    .sub-main {
        display: grid;
        width: 100%;
        grid-template-columns: 10px 1fr 2fr 4px;
        grid-template-rows: 1fr;
    }
}