.main {
    margin-left: 100px;
    margin-right: 100px;
}
.forbold p{
    font-weight: bold;
}
.forbold span{
    font-weight: normal;
}
.car-image {
    width: 100%;
    height: 300px;
    object-fit: contain;
    display: block;
    margin: auto;
}

.slide-item {
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
}

.car-info {
    text-align: center;
}

.car-name {
    font-size: 1.2rem;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    .main {
        margin-left: 0;
        margin-right: 0;
    }
}