/* Fullscreen container to take full width and height */
.fullscreen-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 0, 0.1); /* Slight yellow tint */
    z-index: 9999; /* Ensures it's above all other content */
  }
  
  /* Container for the logo and loader */
  .loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Logo styling */
  .loader-logo {
    width: 100px; /* Adjust the size of the logo */
    margin-bottom: 20px;
  }
  
  /* Loader styling */
  .loader {
    width: 20px; /* Adjust size as needed */
    aspect-ratio: 1;
    background: #f1c40f; /* Yellow color */
    box-shadow: 0 0 60px 15px #f1c40f;
    transform: translate(-80px);
    clip-path: inset(0);
    animation:
      l4-1 0.5s ease-in-out infinite alternate,
      l4-2 1s ease-in-out infinite;
  }
  
  /* Animation keyframes */
  @keyframes l4-1 {
    100% {
      transform: translateX(80px);
    }
  }
  
  @keyframes l4-2 {
    33% {
      clip-path: inset(0 0 0 -100px);
    }
    50% {
      clip-path: inset(0 0 0 0);
    }
    83% {
      clip-path: inset(0 -100px 0 0);
    }
  }
  