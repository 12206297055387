  h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  h2 {
    font-size: 1.8em;
    margin: 15px 0;
    color: #34495e;
  }

  
  /* List styling */
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  ol {
    list-style-type: decimal;
    margin-left: 20px;
  }
  
  /* Strong text styling */
  strong {
    color: #e74c3c;
  }
  


  /* Responsive adjustments */
  @media (max-width: 600px) {
    h1 {
      font-size: 2em;
    }
  
    h2 {
      font-size: 1.5em;
    }
  }
  