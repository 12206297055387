.whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.logo-size {
  font-size: 3rem;
  position: relative;
  display: inline-block;

}

.whatsapp div {
  position: relative;
  width: fit-content;
}



/* Call button - Running background effect */


@keyframes borderShine {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes backgroundRun {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}