.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    align-items: center;
    width: 100%;


}

.paragraph {
    padding: 50px;
    text-align: start;
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    width: 100%;

}




@media only screen and (min-width: 601px) and (max-width: 900px) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1fr);
        width: 100%;
    }

    .img2 img {
        /* display: none; */
        width: 250px;
        height: 320px;

    }

    .paragraph {
        padding: 25px;
    }

}

@media only screen and (max-width: 600px) {

    .img2 img {
        /* display: none; */
        width: 150px;
        height: 200px;

    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
        width: 100%;

    }

    .paragraph {
        padding: 20px;

    }


}

.button-container {
    display: flex;
    flex-direction: column;
  }
  
  .section-button {
    background: none;
    border: none;
    padding: 10px;
    text-align: left;
    cursor: pointer;
    font-size: 1.2rem;
    color: #333;
  }
  
  .section-button.active {
    font-weight: bold;
    color: #000000; /* Change to your desired active color */
    border-bottom: 2px solid #dbd6cf; /* Optional underline for active button */
  }
  
  .content-container {
    padding: 40px;
  }
  